import { Block } from "@hyperobjekt/material-ui-website";
import { Box, withStyles } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import HomeIcon from "../gatsby-theme-hypersite/icons/home";
import Typography from "../components/typography";
import { FONTS } from "../theme";
import {
  Breadcrumb,
  useBreadcrumb,
} from "../gatsby-theme-hypersite/breadcrumb";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(12, 0, 15),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(12, 0, 10),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12, 0, 15),
    },
  },
  container: {
    justifyContent: "flex-start",
    gap: theme.typography.pxToRem(48),
    [theme.breakpoints.up("md")]: {
      gap: theme.typography.pxToRem(56),
    },
  },
  videoContainer: {
    padding: "0 !important",
    background: `linear-gradient(180deg, ${theme.palette.background.dark} 0%, ${theme.palette.background.dark} 35%, ${theme.palette.background.paper} 35%)`,
  },
  video: {
    aspectRatio: 16 / 9,
    width: "100%",
  },
  caption: {
    marginTop: theme.spacing(2),
    fontStyle: "italic",
    textAlign: "center",
  },
  description: {
    padding: "40px 0 40px",
    borderTop: `6px solid ${theme.palette.primary.main}`,
    borderBottom: `6px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontFamily: FONTS.KNOCKOUT.Middleweight.fontFamily,
    fontSize: theme.typography.pxToRem(40),
    lineHeight: "50px",
    letterSpacing: "1.2px",
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(60),
      lineHeight: "70px",
    },
  },
  festivals: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: 48,

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      gap: 132,
    },
  },
  content: {
    maxWidth: "56em",
  },
  festivalEntries: {
    color: theme.palette.primary.main,
    ...FONTS.KNOCKOUT.Lightweight,
    fontSize: "30px",
    lineHeight: "39px",
    letterSpacing: "1.2px",
  },
  images: {
    marginTop: 26,
    display: "flex",
    gap: 40,
    flexWrap: "wrap",
  },
  entry: {
    width: 88,
    height: 88,
  },
});

const Movie = ({ classes, ...props }) => {
  const { root, container } = classes;

  return (
    <>
      <Block className={classes.videoContainer} {...props}>
        <Box className={classes.video}>
          <iframe
            title="Shame On You!"
            src="https://player.vimeo.com/video/843020712?h=f09ed6e8ab"
            style={{
              border: 0,
              width: "100%",
              height: "100%",
            }}
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
          <h2 className={classes.caption}>
            Shame on You! <span style={{ fontStyle: "normal" }}>(11 min.)</span>
          </h2>
        </Box>
      </Block>
      <Block
        bgcolor="background.paper"
        classes={{ root, container }}
        {...props}
      >
        <Box>
          <Box className={classes.description}>
            The film exposes how the debt collection industry is enabled by
            shame as well as fear of the judicial system.
          </Box>
        </Box>
        <StaticImage src={"./images/documentary-banner.png"} alt="" />
        <Box className={classes.festivals}>
          <Box className={classes.content}>
            <Typography>
              The film premiered at its home campus of Princeton University in
              December 2023. Shame On You! was produced, directed, and edited by
              Jeffrey Himpele in the Anthropology VizE Lab at Princeton. It is
              based on extensive interviews and a script by investigative
              journalist Ann Neumann and features voice actresses Vivia Font,
              Dana Green, and Melody Butiu. The film was animated by Michael
              Arthur. Debt Collection Lab founder Frederick F. Wherry is the
              film’s executive director.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.festivalEntries}>
          <Box>Festival Entries</Box>
          <Box className={classes.images}>
            <StaticImage
              className={classes.entry}
              src={"./images/spark.jpg"}
              alt="Spark Computer Graphics Society"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/LAAF.jpg"}
              alt="Los Angeles Animation Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/DC-festival.jpg"}
              alt="Washington DC International Cinema Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/documentaries-without-borders.jpg"}
              alt="Documentaries Without Borders International Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/best-shorts-competition.jpg"}
              alt="Best Shorts Competition"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/ethnografilm.jpg"}
              alt="Ethnografilm"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/impact-docs-awards.jpg"}
              alt="Best Shorts Competition"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/spotlight-on-academics.jpg"}
              alt="Spotlight on Academics Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/montreal-logo.jpeg"}
              alt="Montreal Independent Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/NJIFF.jpg"}
              alt="New Jersey International Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/boston-indie.jpg"}
              alt="Boston Indie Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/chicago-logo.jpg"}
              alt="Chicago Indie Film Awards"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/NYICF.jpg"}
              alt="New York Independent Cinema Awards"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/seattle-logo.jpg"}
              alt="Seattle Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/NJDFF.jpg"}
              alt="New Jersey Documentary Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/sva-logo.jpg"}
              alt="Society for Visual Anthropology"
            />
          </Box>
        </Box>
      </Block>
    </>
  );
};

export default withStyles(styles)(Movie);
