import { Hero } from "@hyperobjekt/material-ui-website";
import { withStyles } from "@material-ui/styles";
import { Link } from "gatsby-theme-material-ui";
import React from "react";
import TwoColBlock from "../components/sections/two-col-block";
import Typography from "../components/typography";
import { FONTS } from "../theme";
import { StaticImage } from "gatsby-plugin-image";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(15, 0, 15),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(12, 0, 10),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12, 0, 15),
    },

    "& .download-button": {
      marginTop: theme.spacing(1),
    },
  },
  container: {
    justifyContent: "flex-start",
    display: "flex",
    "& > div": {
      width: "100%",
      position: "static",
    },
  },
  content: {
    maxWidth: "15em",
    zIndex: 2,
    ...FONTS.KNOCKOUT["Lightweight"],
    letterSpacing: "0.02em",
    fontSize: theme.typography.pxToRem(36),
    lineHeight: 70 / 60,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(48),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.typography.pxToRem(60),
      marginTop: theme.spacing(7),
    },
  },
  smear: {
    zIndex: 1,
    whiteSpace: "nowrap",
    position: "relative",
    "& > span": {
      position: "relative",
      zIndex: 3,
    },
    "&:before": {
      content: "''",
      position: "absolute",
      top: "0.35em",
      left: "calc(-0.5em)",
      width: "calc(100% + .65em)",
      height: "calc(100% - .5em)",
      backgroundImage: `url(/images/paint-smear.png)`,
      backgroundSize: `100% 100%`,
      backgroundPosition: "left",
      backgroundRepeat: "no-repeat",
      zIndex: -1,
    },
  },
  image: {
    zIndex: -1,
    width: 230,
    position: "absolute !important",
    bottom: 62,
    right: 56,
    [theme.breakpoints.up("sm")]: {
      width: 250,
    },
    [theme.breakpoints.up("md")]: {
      width: 320,
    },
    [theme.breakpoints.up("lg")]: {
      width: 370,
    },
  },
  text: {
    width: "100%",
    margin: "3rem auto",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
});

const Laws = ({ classes = {}, ...props }) => {
  const { root, container } = classes;
  return (
    <>
      <Hero bgcolor="background.dark" classes={{ root, container }}>
        <Typography variant="h1" className={classes.content}>
          <span className={classes.smear}>Debt collection</span> regulations
          vary widely by state and territory. Use this tool to compare policies
          across the nation.
        </Typography>
        <StaticImage
          className={classes.image}
          alt="gavel"
          src={"./hero-image.png"}
        />
      </Hero>
      <iframe
        title="laws"
        id="iframe"
        width="100%"
        referrerPolicy="origin"
        src="https://prod-tile-plugin.lawatlas.org/#/debt-collection-litigation-laws?site=netlify.app"
        style={{
          height: "1040px",
          width: "100vw",
          border: "none",
          marginTop: "8em",
        }}
      />
      <TwoColBlock
        bgcolor="background.paper"
        left={
          <Typography variant="underline" component="h2">
            About this dataset
          </Typography>
        }
        right={
          <div className={`block ${classes.text}`}>
            <Typography variant="body1">
              This longitudinal dataset provides a comprehensive overview of
              state statutes, state regulations, and court rules governing debt
              collection lawsuits from January 1, 2023, through December 1,
              2023, in all 50 U.S. states, the District of Columbia, American
              Samoa, Guam, Northern Mariana Islands, Puerto Rico, and the U.S.
              Virgin Islands. It is part of LawAtlas, developed by the Center for
              Public Health Law Research at Temple University Beasley School of
              Law. Help using the tool{" "}
              <Link href="https://lawatlas.org/how-to-use-lawatlas">
                can be found here
              </Link>
              .
            </Typography>
            <br />
            <Typography variant="body1">
              This dataset was created in collaboration with The Pew Charitable
              Trusts. Any views expressed here do not necessarily reflect the
              views of The Pew Charitable Trusts. The Debt Collection Lab is
              collaborating with the Temple LawAtlas to explore new ways to
              visualize, merge, and analyze data on the laws that govern debt
              collection with data on debt collection lawsuits.
            </Typography>
            <br />
            <Typography variant="body1">
              Please note: the information contained herein does not constitute
              legal advice. If you have questions regarding your legal rights or
              obligations, contact an attorney in your jurisdiction.
              (Information about the dataset is extracted directly from the
              source site:
              <Link href="https://lawatlas.org/datasets/debt-collection-litigation-laws">
                https://lawatlas.org/datasets/debt-collection-litigation-laws
              </Link>{" "}
              which is powered by MonQcle.)
            </Typography>
            <br />
            <Typography variant="body1">
              To cite the Law Dataset, please use the following: Temple
              University Center for Public Health Law Research (2024, August 2).
              State Debt Collection Litigation Laws.{" "}
              <Link href="http://lawatlas.org">LawAtlas.org</Link>:{" "}
              <Link href="https://lawatlas.org/datasets/debt-collection-litigation-laws">
                https://lawatlas.org/datasets/debt-collection-litigation-laws
              </Link>{" "}
            </Typography>
          </div>
        }
        {...props}
      />
    </>
  );
};

export default withStyles(styles)(Laws);
